import React from "react"

import Seo from "./seo"
import LanguageWrapper from "../wrappers/LanguageWrapper";
import "./../assets/css/customUiKit.css"
import Nav from "./nav"

const Layout = ({ children, seo = {}, urlLang }) => {
  const { title, description } = seo;
  return (
    <LanguageWrapper urlLang={urlLang}>
      <Seo title={title} description={description}/>
      <Nav/>
      <main>{children}</main>
    </LanguageWrapper>
  )
};

export default Layout
