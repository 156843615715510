import React, { useContext, useEffect } from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import favicon from "./../images/favicon.ico"
import LanguageContext from "../wrappers/contexts/LanguageContext"

function SEO({ description, meta, title }) {
  const { getTranslate, lang } = useContext(LanguageContext)
  const { site, allStrapiSeo } = useStaticQuery(querySeo)

  useEffect(() => {
    if (typeof window !== "undefined") {
      require("uikit/dist/js/uikit")
      require("uikit/dist/js/uikit.min")
      require("uikit/dist/js/uikit-icons.min")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const metaDescription = getTranslate(description) || getTranslate(allStrapiSeo.edges[0].node.description.translate) || site.siteMetadata.description
  const newTitle = getTranslate(title) || getTranslate(allStrapiSeo.edges[0].node.title.translate)
  const icon = favicon

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={newTitle}
      titleTemplate={`%s | ${getTranslate(allStrapiSeo.edges[0].node.title.translate) || site.siteMetadata.title}`}
      link={[
        {
          rel: "icon",
          href: icon,
        },
      ]}
      script={[]}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: newTitle,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: newTitle,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
    />
  )
}

SEO.defaultProps = {
  meta: [],
}

SEO.propTypes = {
  meta: PropTypes.arrayOf(PropTypes.object),
}

const querySeo = graphql`
      query {
            allStrapiSeo {
          edges {
            node {
              description {
                translate {
                  text
                  language {
                    lang
                  }
                }
              }
              title {
                translate {
                  text
                  language {
                    lang
                  }
                }
              }
            }
          }
        }
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `

export default SEO
