import React, { useContext, useEffect, useState } from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import getWindowSize from "../utils/getWindowSize"
import LanguageContext from "../wrappers/contexts/LanguageContext"

const breakPoints = [700, 900, 1100, 1300]

export default () => {
  const { getTranslate, languages, changeLang, lang } = useContext(LanguageContext)
  const [{ visible, invisible }, setCategories] = useState({ visible: [], invisible: [] })
  const [maxWidth, setMaxWidth] = useState(null)
  const data = useStaticQuery(queryCategories)
  const { width } = getWindowSize()

  useEffect(() => {
    const point = (breakPoints.filter(point => width < point)[0] || 0)
    setMaxWidth(point)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width])

  useEffect(() => {
    if (maxWidth !== null) {
      if (width < 700) {
        changeVisibleCategories(0)
      } else if (width < 900) {
        changeVisibleCategories(1)
      } else if (width < 1100) {
        changeVisibleCategories(2)
      } else if (width < 1300) {
        changeVisibleCategories(3)
      } else {
        changeVisibleCategories(4)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [maxWidth])

  const changeVisibleCategories = (num) => {
    const filterCategories = data.allStrapiCategory.edges.filter(item => (item.node.articles || []).length > 0)
    setCategories({
      visible: (filterCategories.slice(0, num)),
      invisible: filterCategories.slice(num, data.allStrapiCategory.edges.length),
    })
  }


  return (<div>
    <div>
      <nav className="uk-navbar-container" data-uk-navbar>
        <div className="uk-navbar-left">
          <ul className="uk-navbar-nav">
            <li>
              <Link to={"/" + lang + "/"}>{getTranslate("Título")}</Link>
            </li>
          </ul>
        </div>

        <div className="uk-navbar-right">
          <ul className="uk-navbar-nav">
            {visible.map((category) =>
              <li key={category.node.strapiId}>
                <Link to={`/${lang}/category/${category.node.strapiId}`}>
                  {getTranslate(category.node.name.translate)}
                </Link>
              </li>)}
            {invisible.length > 0 &&
            <li>
              <a href="/" onClick={e => e.preventDefault()}>+ {getTranslate("Categorías")}</a>
              <div className="uk-navbar-dropdown">
                <ul className="uk-nav uk-navbar-dropdown-nav">
                  {invisible.map((category) =>
                    <li key={category.node.strapiId}>
                      <Link to={`/${lang}/category/${category.node.strapiId}`}>
                        {getTranslate(category.node.name.translate)}
                      </Link>
                    </li>)}
                </ul>
              </div>
            </li>
            }
            {languages.length > 1 &&
            <li>
              <a href="/" onClick={e => e.preventDefault()}>{lang}</a>
              <div className="uk-navbar-dropdown">
                <ul className="uk-nav uk-navbar-dropdown-nav">
                  {languages.map((lang, key) =>
                    <li key={key}>
                      <div role={"button"} tabIndex={0} style={{ cursor: "pointer", padding: "5px" }}
                           onClick={() => changeLang(lang)}
                           onKeyDown={() => changeLang(lang)}>
                        {lang}
                      </div>
                    </li>)}
                </ul>
              </div>
            </li>
            }
          </ul>
        </div>
      </nav>
    </div>
  </div>)
};

const queryCategories = graphql`
                query {
                  allStrapiCategory {
                    edges {
                      node {
                        strapiId
                          name {
                            translate {
                              language {
                                lang
                              }
                              text
                            }
                         }
                         articles {
                          id
                        }
                      }
                    }
                  }
                }
              `
