import React, { useEffect } from "react"
import LanguageContext from "./contexts/LanguageContext"
import { graphql, useStaticQuery } from "gatsby"
import { navigate } from "gatsby"

const defaultLang = "es"

const getLocalLang = () => {
  if (typeof window !== "undefined") {
    return (localStorage.getItem("lang-blog") || defaultLang)
  }
  return defaultLang
}

export default ({ children, urlLang }) => {
  const data = useStaticQuery(generalTranslatesQuery)
  const strapiLanguages = data.allStrapiLanguage.edges
  const lang = (urlLang || getLocalLang())

  useEffect(() => {
    if (typeof window !== "undefined") {
      localStorage.setItem("lang-blog", lang.toString())
    }
  }, [lang])

  const getTranslate = (objectToTranslate) => {
    if (Array.isArray(objectToTranslate)) {
      const translate = objectToTranslate.filter(tra => (tra.language?.lang || null) === lang)[0]
      if (translate) {
        return translate.text || translate.content
      } else if (objectToTranslate[0]) {
        return objectToTranslate[0].text || objectToTranslate[0].content
      }
      return null
    } else {
      const optData = data.allStrapiGeneralTranslation.nodes
      const found = optData.find(trans => trans.name === objectToTranslate)
      if (found) {
        const translates = found.text.translate
        return getTranslate(translates) || objectToTranslate
      }
      return objectToTranslate
    }
  }

  const changeLang = (newLang) => {
    if (typeof window !== "undefined") {
      const actualPath = window.location.pathname
      const existsLangOnPath = actualPath.indexOf(("/" + lang + "/")) >= 0
      const existsOnlyLangOnPath = (actualPath + " ").indexOf(("/" + lang)) >= 0
      if (existsLangOnPath) {
        const path = actualPath.replace(("/" + lang + "/"), ("/" + newLang + "/"))
        navigate(path, { replace: true })
      } else if (existsOnlyLangOnPath) {
        const path = actualPath.replace(("/" + lang), ("/" + newLang + "/"))
        navigate(path, { replace: true })
      } else {
        const oldPath = window.location.pathname
        navigate((("/" + newLang + oldPath)), { replace: true })
      }
    }
  }

  const languages = []
  strapiLanguages.forEach(item => languages.push(item.node.lang))

  return (
    <LanguageContext.Provider value={{ getTranslate, lang, languages, changeLang }} children={children}/>
  )
};

const generalTranslatesQuery = graphql`
  {
    allStrapiGeneralTranslation {
      nodes {
        name
        text {
          translate {
            language {
              lang
            }
            text
          }
        }
      }
    }
    allStrapiLanguage {
      edges {
        node {
          lang
        }
      }
    }
  }
`


